import React from 'react';
import './Banner.css';

const Banner = props => {
    return (
        <>
            <section className="banner">
                <p className="banner__text banner__title">{props.lang === 'eng' ? "Thank you for applying." : "Dziękujemy za aplikację."}</p>
                {/* <p className="banner__text">{props.lang === 'eng' ? "We will contact you soon." : "Skontaktujemy się z Tobą wkrótce."}</p> */}
                <p className="banner__text">{props.lang === 'eng' ? "To complete the application process send your CV to:" : "Aby dokończyć proces aplikacji prześlij swoje CV na adres:"}</p>
                <p className="banner__text"><a className="banner__link" href="mailto:accenture@higher.pl?subject=Accenture%20Application%20-%20CV">accenture@higher.pl</a></p>
                <button className="banner__btn" onClick={props.close}>{props.lang === 'eng' ? "close" : "zamknij"}</button>
            </section>
        </>
    );
}

export default Banner;