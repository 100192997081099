import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./Home.css";
import Sign from "./sign.png"
import Fade from 'react-reveal/Fade';
import Logo from "./logo.png";

class Home extends Component {

    state = {
        isExtraVisible: false
    }

    handleExtra = () => {
        this.setState({ isExtraVisible: !this.state.isExtraVisible });
        console.log(this.state.isExtraVisible);

    }

    render() {
        return (
            <>
                <div className="home" id="home">
                    <div className="home__header">
                        <img className="home__image" src={Logo} alt="Accenture Logo" />
                    </div>
                    <main className="main">
                        <div className="home__logo-wrap">
                            <div className="home__logo">
                                <img src={Sign} alt="Logo Accenture" />
                                <div className="home__texts">
                                    <p>new</p>
                                    <p>applied</p>
                                    <p>now</p>
                                </div>
                            </div>
                        </div>
                        <div className="home__offers">
                            <div className="offers__wrap">
                                <Link to="/business_intelligence">
                                    <button className="home__button">BUSINESS INTELLIGENCE</button>
                                </Link>
                                <Link to="/testing">
                                    <button className="home__button">TESTING</button>
                                </Link>
                                <Link to="/front_end">
                                    <button className="home__button">FRONT-END</button>
                                </Link>
                                <Link to="/java">
                                    <button className="home__button">JAVA</button>
                                </Link>
                                <Link to="/intelligent_cloud_and_infrastructure">
                                    <button className="home__button">INTELLIGENT CLOUD & INFRASTRUCTURE</button>
                                </Link>
                            </div>
                        </div>
                    </main>
                    <footer className="footer">
                        <div className="footer__texts">
                            <p>Come innovate</p>
                            {/* <p>do drużyny</p> */}
                            <p>with us!</p>
                        </div>

                        <section className="section offer__contact" id="contact">
                            <form className="form" method="POST" action="index.php">
                                <input type="hidden" name="offer" value="General Form" />
                                <Fade up>
                                    <input placeholder="First name" name="firstName" required />
                                </Fade>
                                <Fade up>
                                    <input placeholder="Last name" name="lastName" required />
                                </Fade>
                                <Fade up>
                                    <input type="email" placeholder="Email" name="email" required />
                                </Fade>
                                <Fade up>
                                    <input type="number" placeholder="Phone number" name="phoneNumber" required />
                                </Fade>
                                <Fade up>
                                    <select name="type" id="type" defaultValue={"DEFAULT"}>
                                        <option value="DEFAULT" disabled>Collaboration type</option>
                                        <option value="Work">Work</option>
                                        <option value="Internship">Internship</option>
                                    </select>
                                </Fade>
                                <Fade up>
                                    <select name="city" id="city" defaultValue={"DEFAULT"}>
                                        <option value="DEFAULT" disabled>City</option>
                                        <option value="Warsaw">Warsaw</option>
                                        <option value="Cracow">Cracow</option>
                                        <option value="Lodz">Lodz</option>
                                        <option value="Katowice">Katowice</option>
                                    </select>
                                </Fade>
                                <Fade up>
                                    <label className="firstLabel">
                                        <input type="checkbox" required />
                                        <span>I hereby consent to the processing of my personal data by Accenture sp. z o.o. with its registered seat in Warsaw (00-121), at ul. Sienna 39, NIP 526-00-15-900 and Higher Sp. z o.o. with its registered seat in Warsaw (00-079), at ul. Krakowskie Przedmieście 79/300 (Data Controllers), in accordance with the Act of August 29, 1997 on the Protection of Personal Data (Journal of Laws of 2016, item 922)<span className={this.state.isExtraVisible ? "extra visible" : "extra"}> and the Regulation on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (Data Protection Directive), necessary to carry out the recruitment process by Accenture . At the same time, I declare that I provide my personal data completely voluntary. I also declare that I have been informed about my right to withdraw my consent or object to processing of data, request access to them, rectification, deletion, limitation of processing and their transfer, at any time and the right to lodge a complaint to the data protection supervisory authority</span>.</span>
                                    </label>
                                    <div className="extra-toggler" onClick={this.handleExtra}>{this.state.isExtraVisible ? "Show less" : "Show more"}</div>
                                </Fade>
                                <Fade up>
                                    <label>
                                        <input type="checkbox" required />
                                        <span>I have read, understood and accept <a href="https://www.accenture.com/us-en/about/terms-of-use" target="_blank" rel="noopener noreferrer">the Terms and Regulations.</a></span>

                                    </label>
                                </Fade>
                                <Fade up>
                                    <input className="offer__button" name="submit" type="submit" value="Send" />
                                </Fade>
                            </form>
                        </section>

                        <div className="footer__socials">
                            <a href="https://www.linkedin.com/company/accenturepoland?trk=vsrp_companies_cluster_name&trkInfo=VSRPsearchId%3A220393721475495038518%2CVSRPtargetId%3A15154437%2CVSRPcmpt%3Acompanies_cluster?" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                            <a href="https://m.facebook.com/AccentureKariera/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://twitter.com/Accenture" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://m.youtube.com/user/AccenturePoland" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                        </div>
                    </footer>
                    {/* <div id="applyBanner"></div> */}
                </div>
            </>
        )
    };
}

export default Home;