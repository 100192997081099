import React, { Component } from 'react';
import './App.css';
import Home from "./Home";
import OfferA from "./OfferA";
import OfferB from "./OfferB";
import OfferC from "./OfferC";
import OfferD from "./OfferD";
import OfferE from "./OfferE";
import Offer_01 from "./Offer_01";
// import Offer_02 from "./Offer_02";
import Offer_03 from "./Offer_03";
import Offer_04 from "./Offer_04";
import Offer_05 from "./Offer_05";
import Offer_06 from "./Offer_06";
import Offer_07 from "./Offer_07";
import Offer_08 from "./Offer_08";
import Offer_09 from "./Offer_09";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import GA from './utils/GoogleAnalytics';

class App extends Component {
  state = {
    visibleComp: "Home"
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="App">
          {/* {GA.init() && <GA.RouteTracker />} */}
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/business_intelligence" component={OfferA} />
            <Route path="/testing" component={OfferB} />
            <Route path="/front_end" component={OfferC} />
            <Route path="/java" component={OfferD} />
            <Route path="/intelligent_cloud_and_infrastructure" component={OfferE} />

            {/* /////////////////////////////////////////////////////////////////////////////////////// New Routes */}

            <Route path="/business-intelligence" component={Offer_01} />
            {/* <Route path="/testing-engineer" component={Offer_02} /> */}
            <Route path="/java-developer" component={Offer_03} />
            <Route path="/web-application-developer" component={Offer_04} />
            <Route path="/intelligent-cloud-and-infrastructure" component={Offer_05} />
            <Route path="/technology-consulting-analyst" component={Offer_06} />
            <Route path="/manual-testing-engineer" component={Offer_07} />
            <Route path="/salesforce-expert" component={Offer_08} />
            <Route path="/future-salesforce-expert" component={Offer_09} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
