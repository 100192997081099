import React, { Component } from 'react';
import "./Offer.css";
import "./OfferC.css";
import { HashLink } from "react-router-hash-link";
import Fade from 'react-reveal/Fade';
import Gift from "./gift.png";
import Head from "./head.png";
import Compass from "./compass.png";
import Pin from "./pin.png";
import Watch from "./watch.png";
import People from "./people.png";
import Person1 from "./personFront.png";
import Person2 from "./person2.png";
import Logo from "./logo.png";

class OfferC extends Component {

    state = {
        isExtraVisible: false
    }

    handleExtra = () => {
        this.setState({ isExtraVisible: !this.state.isExtraVisible });
        console.log(this.state.isExtraVisible);

    }

    handleScroll = e => {

        var body = document.body,
            html = document.documentElement;

        var documentHeight = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);

        const currentScrollValue = document.documentElement.scrollTop;

        const applyBanner = document.getElementById("applyBanner");

        if (currentScrollValue < .7 * documentHeight) {
            applyBanner.classList.remove("invisible");
        } else {
            applyBanner.classList.add("invisible");
        }

    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        return (
            <>
                <div className="offer c">

                    <div className="offer__apply offer__applyA" id="applyBanner">
                        <div className="section__wrap">
                            <img className="offer__image" src={Logo} alt="Accenture Logo" />
                            <HashLink to="#contact">
                                <span href="#contact"><button className="offer__button">Apply</button></span>
                            </HashLink>
                        </div>

                    </div>

                    <div className="offer__header">
                        <div className="offer__titles offer__wrap">
                            <img className="offer__person1" src={Person1} alt="person" />
                            {/* <img className="offer__image" src={Sign} alt="Accenture Logo" /> */}
                            <Fade up>
                                <h1 className="offer__title">FRONT-END</h1>
                                <h1 className="offer__title">DEVELOPER</h1>
                                {/* <h1 className="offer__title">ANALYST</h1> */}
                            </Fade>
                            <div className="header__info">
                                <div className="row">
                                    <img src={Pin} alt="pin sign" />
                                    <p>Warsaw, Cracow, Katowice, Lodz</p>
                                </div>
                                <div className="row">
                                    <img src={Watch} alt="watch sign" />
                                    <p>Full time</p>
                                </div>
                                <div className="row">
                                    <img src={People} alt="people sign" />
                                    <p>Junior/Mid/Senior</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="section offer__benefits">
                        <div className="section__wrap">
                            <div className="section__content">
                                <Fade down>
                                    <h2 className="offer__subtitle">What we can offer</h2>
                                </Fade>
                                <div className="section__table">
                                    {/* <div className="section__row"> */}
                                    {/* <Fade left>
                                            <div className="section__row-image">
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </Fade> */}
                                    <Fade right>
                                        <div className="section__row-text">Clearly defined career path</div>
                                    </Fade>
                                    {/* </div> */}

                                    <Fade right>
                                        <div className="section__row-text">Lunch vouchers</div>
                                    </Fade>


                                    <Fade right>
                                        <div className="section__row-text">Attractive career opportunities</div>
                                    </Fade>

                                    <Fade right>
                                        <div className="section__row-text">Sports tickets</div>
                                    </Fade>

                                    <Fade right>
                                        <div className="section__row-text">In-house training, workshops, courses </div>
                                    </Fade>


                                    <Fade right>
                                        <div className="section__row-text">Additional life insurance</div>
                                    </Fade>

                                    <Fade right>
                                        <div className="section__row-text">International projects</div>
                                    </Fade>


                                    <Fade right>
                                        <div className="section__row-text">Cyclical integration meetings</div>
                                    </Fade>


                                    <Fade right>
                                        <div className="section__row-text">Private medical care</div>
                                    </Fade>

                                    <Fade right>
                                        <div className="section__row-text">American Express Gold corporate credit card</div>
                                    </Fade>

                                    <Fade right>
                                        <div className="section__row-text">Mentoring</div>
                                    </Fade>

                                    <Fade right>
                                        <div className="section__row-text">Access to e-learning platform</div>
                                    </Fade>
                                    <Fade right>
                                        <div className="section__row-text">Certifications</div>
                                    </Fade>
                                    <Fade right>
                                        <div className="section__row-text">Training budget</div>
                                    </Fade>
                                </div>
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Gift} alt="Gift icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__details">
                        <div className="section__wrap">
                            <img className="offer__person2" src={Person2} alt="person" />
                            {/* <img className="offer__image" src={Sign} alt="Accenture Logo" /> */}
                            <div className="offer__subtitles">
                                <Fade left>
                                    <h2 className="offer__subtitle">Let's improve</h2>
                                </Fade>
                                <Fade left>
                                    <h2 className="offer__subtitle">the world</h2>
                                </Fade>
                                <Fade left>
                                    <h2 className="offer__subtitle green">together</h2>
                                </Fade>
                            </div>
                        </div>
                        {/* <div className="section__wrap">
                            <div className="section__content">
                                <Fade down>
                                    <h2 className="offer__subtitle">Szczegóły oferty</h2>
                                </Fade>
                                <div className="section__table">
                                    <Fade up>
                                        <div className="section__item">
                                            <div className="section__item-image">
                                                <i className="fas fa-dollar-sign"></i>
                                            </div>
                                            <div className="section__item-text">Płatny staż</div>
                                        </div>
                                    </Fade>
                                    <Fade up>
                                        <div className="section__item">
                                            <div className="section__item-image">
                                                <i className="fas fa-shield-alt"></i>
                                            </div>
                                            <div className="section__item-text">Ubezpieczenie na życie</div>
                                        </div>
                                    </Fade>
                                    <Fade up>
                                        <div className="section__item">
                                            <div className="section__item-image">
                                                <i className="fas fa-user-md"></i>
                                            </div>
                                            <div className="section__item-text">Opieka medyczna</div>
                                        </div>
                                    </Fade>
                                    <Fade up>
                                        <div className="section__item">
                                            <div className="section__item-image">
                                                <i className="fas fa-forward"></i>
                                            </div>
                                            <div className="section__item-text">Szybka rekrutacja</div>
                                        </div>
                                    </Fade>
                                    <Fade up>
                                        <div className="section__item">
                                            <div className="section__item-image">
                                                <i className="fas fa-couch"></i>
                                            </div>
                                            <div className="section__item-text">Konferencje</div>
                                        </div>
                                    </Fade>
                                    <Fade up>
                                        <div className="section__item">
                                            <div className="section__item-image">
                                                <i className="fas fa-heart"></i>
                                            </div>
                                            <div className="section__item-text">Karta sportowa</div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div> */}
                    </section>

                    <section className="section offer__tasks">
                        <div className="section__wrap">
                            <div className="section__content">
                                <Fade down>
                                    <div className="offer__subtitles">
                                        <h2 className="offer__subtitle">Your daily tasks</h2>
                                    </div>
                                </Fade>
                                <div className="section__table">
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image"><p>1</p></div>
                                        </Fade>
                                        <Fade left>
                                            <div className="section__row-text">Creation and implementation of innovative web applications for international institutions and companies</div>
                                        </Fade>
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">2</div>
                                        </Fade>
                                        <Fade left>
                                            <div className="section__row-text">Ensuring support in analysis and designing during all phases of software production</div>
                                        </Fade>
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">3</div>
                                        </Fade>
                                        <Fade left>
                                            <div className="section__row-text">Suggesting the most effective solutions for implementation</div>
                                        </Fade>
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">4</div>
                                        </Fade>
                                        <Fade left>
                                            <div className="section__row-text">Designing complex solutions, which have to be compatible with clients’ expectations and users’ geographical dispersion</div>
                                        </Fade>
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">5</div>
                                        </Fade>
                                        <Fade left>
                                            <div className="section__row-text">Work in interdisciplinary teams combining technical, business and data science competencies</div>
                                        </Fade>
                                    </div>
                                    {/* <div className="section__row">
                                        <Fade right>
                                            <div className="section__row-image">6</div>
                                        </Fade>
                                        <Fade left>
                                            <div className="section__row-text">Preparation and conducting trainings for system users</div>
                                        </Fade>
                                    </div> */}
                                </div>
                            </div>
                            <Fade left>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Compass} alt="Compass icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__expect">
                        <div className="section__wrap">
                            <div className="section__content">
                                <Fade down>
                                    <h2 className="offer__subtitle">What we're looking for</h2>
                                </Fade>
                                <div className="must-have">
                                    <Fade left>
                                        <h3>Must have</h3>
                                    </Fade>
                                    <Fade left>
                                        <div className="features">
                                            <p className="feature">HTML</p>
                                            <p className="feature">CSS</p>
                                            <p className="feature">SPA</p>
                                            <p className="feature">MVC</p>
                                            <p className="feature">JavaScript</p>
                                            <p className="feature">object programming</p>
                                            <p className="feature">project patterns</p>
                                            <p className="feature">communication skills</p>
                                            <p className="feature">English</p>
                                        </div>
                                    </Fade>
                                </div>
                                <br />
                                <div className="must-have">
                                    <Fade left>
                                        <h3>Nice to have</h3>
                                    </Fade>
                                    <Fade left>
                                        <div className="features">
                                            <p className="feature">Angular.js</p>
                                            <p className="feature">Knockout.js</p>
                                            <p className="feature">React.js</p>
                                            <p className="feature">Aurelia</p>
                                            <p className="feature">Xamarin technology</p>
                                            <p className="feature">REST</p>
                                            <p className="feature">SOAP</p>
                                            <p className="feature">Jira</p>
                                            <p className="feature">Bitbucket</p>
                                            <p className="feature">Git</p>
                                            <p className="feature">TypeScript</p>
                                            <p className="feature">Backbone</p>
                                        </div>
                                    </Fade>
                                </div>
                                {/* <div className="section__table">
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">
                                                <i className="fas fa-database"></i>
                                            </div>
                                        </Fade>
                                        <Fade right>
                                            <div className="section__row-text">Znajomość SQL</div>
                                        </Fade>
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">
                                                <i className="fas fa-microchip"></i>
                                            </div>
                                        </Fade>
                                        <Fade right>
                                            <div className="section__row-text">Doświadczenie w technologii ETL</div>
                                        </Fade>
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">
                                                <i className="fas fa-globe"></i>
                                            </div>
                                        </Fade>
                                        <Fade right>
                                            <div className="section__row-text">Język angielski</div>
                                        </Fade>
                                    </div>
                                </div> */}
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Head} alt="Head icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__about">
                        <div className="section__wrap">
                            <div className="section__video">
                                <iframe src="https://www.youtube.com/embed/ux7Ef5-0g5A?autoplay=0&mute=0&controls=0&origin=https%3A%2F%2Fworkate.pl&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1" frameBorder="0" scrolling="no" title="About Accenture"></iframe>
                            </div>
                        </div>
                    </section>

                    <section className="section offer__contact" id="contact">
                        <div className="section__wrap">
                            <div className="offer__subtitles">
                                <Fade down>
                                    <h2 className="offer__subtitle">Let's join forces!</h2>
                                </Fade>
                                <Fade down>
                                    <h2 className="offer__subtitle green">Apply now!</h2>
                                </Fade>
                            </div>
                        </div>
                        <form className="form" id="formOfferA" method="POST" action="index.php">
                            <input type="hidden" name="offer" value="Front-End Developer" />
                            <Fade up>
                                <input placeholder="Fist name" name="firstName" required />
                            </Fade>
                            <Fade up>
                                <input placeholder="Last name" name="lastName" required />
                            </Fade>
                            <Fade up>
                                <input type="email" placeholder="Email" name="email" required />
                            </Fade>
                            <Fade up>
                                <input type="number" placeholder="Phone number" name="phoneNumber" required />
                            </Fade>
                            <input type="hidden" name="type" value="/Only applicable for the general form./" />
                            <Fade up>
                                <select name="city" id="city" defaultValue={"DEFAULT"}>
                                    <option value="DEFAULT" disabled>City</option>
                                    <option value="Warsaw">Warsaw</option>
                                    <option value="Cracow">Cracow</option>
                                    <option value="Katowice">Katowice</option>
                                    <option value="Lodz">Lodz</option>
                                </select>
                            </Fade>
                            <Fade up>
                                <label className="firstLabel">
                                    <input type="checkbox" required />
                                    <span>I hereby consent to the processing of my personal data by Accenture sp. z o.o. with its registered seat in Warsaw (00-121), at ul. Sienna 39, NIP 526-00-15-900 and Higher Sp. z o.o. with its registered seat in Warsaw (00-079), at ul. Krakowskie Przedmieście 79/300 (Data Controllers), in accordance with the Act of August 29, 1997 on the Protection of Personal Data (Journal of Laws of 2016, item 922)<span className={this.state.isExtraVisible ? "extra visible" : "extra"}> and the Regulation on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (Data Protection Directive), necessary to carry out the recruitment process by Accenture. At the same time, I declare that I provide my personal data completely voluntary. I also declare that I have been informed about my right to withdraw my consent or object to processing of data, request access to them, rectification, deletion, limitation of processing and their transfer, at any time and the right to lodge a complaint to the data protection supervisory authority</span>.</span>
                                </label>
                                <div className="extra-toggler" onClick={this.handleExtra}>{this.state.isExtraVisible ? "Show less" : "Show more"}</div>
                            </Fade>
                            <Fade up>
                                <label>
                                    <input type="checkbox" required />
                                    <span>I have read, understood and accept <a href="https://www.accenture.com/us-en/about/terms-of-use" target="_blank" rel="noopener noreferrer">the Terms and Regulations.</a></span>
                                </label>
                            </Fade>
                            <Fade up>
                                <input className="offer__button" name="submit_offer_01" type="submit" value="Send" />
                            </Fade>
                        </form>
                    </section>
                    <footer className="offer__footer">
                        <Fade down>
                            {/* <Link to="/#home"> */}
                            <HashLink to="/#home">
                                <button className="offer__button">Back to offers</button>
                            </HashLink>
                            {/* </Link> */}
                        </Fade>
                    </footer>
                    {/* <div className="offer__space"></div> */}
                </div>
            </>
        );
    }

}

export default OfferC;